<template>
  <div class="accordion" :class="[wrapperClass, guideClass]">
    <div class="accordion-trigger" :class="accordionTriggerClass">
      <button
        @click="toggleActive"
        class="accordion-trigger__button"
        :class="[
          accordionTriggerButtonClass,
          accordionStatusClass,
          guideTriggerButtonClass,
        ]"
        aria-haspopup="true"
        :aria-controls="accordionContentId"
        :aria-expanded="active ? 'true' : 'false'"
      >
        <slot name="heading"></slot>
        <div class="accordion-trigger__post-heading">
          <slot name="post-heading"></slot>
        </div>
      </button>
    </div>
    <div
      class="accordion-content-wrapper"
      :class="[
        accordionStatusClass,
        accordionContentWrapperGuideClass,
        accordionContentWrapperClass,
      ]"
      :id="accordionContentId"
    >
      <div
        class="accordion-content mb-3"
        :class="[accordionContentClass, accordionContentGuideClass]"
        v-show="active"
      >
        <slot name="content" :active="active"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { uniqueId } from "lodash";
import { mapMutations } from "vuex";
export default {
  name: "AccordionComponent",
  props: {
    openDefault: {
      type: Boolean,
      default: true,
    },
    accordionHeaderClass: {
      type: String,
      default: "",
    },
    accordionContentWrapperClass: {
      type: String,
      default: "",
    },
    accordionContentClass: {
      type: String,
      default: "",
    },
    navInfo: {
      type: String,
      required: false,
      default: "",
    },
    accordionTriggerButtonClass: {
      type: String,
      default: "accordion-trigger__button--after",
    },
    wrapperClass: {
      type: String,
      default: "",
    },
    isGuide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: this.openDefault,
    };
  },
  methods: {
    ...mapMutations("navigation", ["updateNavOpenDefaults"]),
    toggleActive() {
      this.active = !this.active;
      if (this.navInfo) {
        this.updateNavOpenDefaults({ name: this.navInfo, value: this.active });
      }
    },
  },
  computed: {
    accordionTriggerClass() {
      const triggerClass = [this.accordionHeaderClass];
      if (this.active) triggerClass.push("open");
      if (this.isGuide) triggerClass.push("accordion-trigger--guide");
      return triggerClass;
    },
    accordionStatusClass() {
      if (this.active) {
        return "is-open";
      }
      return "is-closed";
    },
    guideClass() {
      return this.isGuide ? "accordion--guide" : "";
    },
    accordionContentId() {
      return uniqueId("accordion");
    },
  },
  created() {
    this.guideTriggerButtonClass = this.isGuide
      ? "accordion-trigger__button--guide"
      : "";
    this.accordionContentGuideClass = this.isGuide
      ? "accordion-content--guide pr-3"
      : "";
    this.accordionContentWrapperGuideClass = this.isGuide
      ? "accordion-content-wrapper--guide"
      : "";
  },
};
</script>
