<template>
  <header class="gc-header">
    <vue-skip-to to="#main" text="Skip to main content" />
    <nav
      class="gc-header__navbar navbar"
      role="navigation"
      :class="impersonating ? 'preview-mode' : 'notpreview'"
    >
      <the-logo :icon-only="headerDataPresent" />
      <ul
        v-if="headerDataPresent && course"
        class="gc-header__nav-list--course-info"
      >
        <li class="gc-header__list-item">
          <a
            class="no-hover gc-header__site-title-link"
            :href="courseDashboardUrl"
          >
            <span class="gc-header__course-number">
              {{ headerData.course_number }}&nbsp;
            </span>
            <span class="gc-header__course-name">
              {{ headerData.name }}
            </span>
            <span class="gc-header__course-semester">
              {{ headerData.semester }} {{ headerData.year }}
            </span>
          </a>
        </li>
        <li class="gc-header__list-item">
          <course-info :header="true" />
        </li>
      </ul>

      <a
        class="gc-header__release-preview-link"
        v-if="impersonating"
        :href="exitImpersonate"
        ><i class="fa fw fa-eye-slash mr-1"></i>Exit Preview</a
      >

      <div class="gc-header__user-actions" ref="clickAway">
        <course-announcements v-if="headerDataPresent && course" />
        <account-menu />
      </div>
    </nav>
    <button
      @click="animateMobileMenu"
      aria-label="Mobile navigation"
      :aria-expanded="ariaExpanded"
      class="gc-header__mobile-navbar-toggle hide-for-large"
    >
      <i class="fa fa-bars fa-fw"></i>
    </button>
    <VueAnnouncer class="sr-only" />
    <banner-notifications v-if="isStaff" />
  </header>
  <div class="nav-flyout-contentmask" @click="animateMobileMenu"></div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import AccountMenu from "./AccountMenu.vue";
import CourseAnnouncements from "./CourseAnnouncements.vue";
import CourseInfo from "./CourseInfo.vue";
import TheLogo from "./TheLogo.vue";
import BannerNotifications from "../notifications/BannerNotifications.vue";
import { courseDashboardUrl, exitImpersonateUrl } from "@/courses/urls.js";
export default {
  name: "HeaderComponent",
  components: {
    AccountMenu,
    CourseAnnouncements,
    CourseInfo,
    TheLogo,
    BannerNotifications,
  },
  props: {
    impersonating: {
      type: Boolean,
    },
  },
  data() {
    return {
      ariaExpanded: false,
    };
  },
  computed: {
    ...mapState(["course"]),
    ...mapState("course", ["headerData", "courseId"]),
    ...mapGetters(["isStaff"]),
    courseDashboardUrl() {
      let url = "";
      if (this.courseId) {
        url = courseDashboardUrl.stringify({ courseId: this.courseId });
      }
      return url;
    },
    exitImpersonate() {
      return exitImpersonateUrl.stringify();
    },
    headerDataPresent() {
      return this.headerData != null;
    },
  },
  created() {
    window.addEventListener("resize", () => {
      let body = document.querySelector("body");
      let scrollingDisabled = body.classList.contains("noscroll");

      let offscreenSidebar = document.querySelector(".offscreen-sidebar");
      let menuVisible = offscreenSidebar.classList.contains("menu-visible");

      let desktopSized = window.innerWidth >= 1024;

      if (desktopSized && scrollingDisabled) {
        body.classList.remove("noscroll");
      } else if (!desktopSized && menuVisible) {
        body.classList.add("noscroll");
      }
    });
  },
  methods: {
    animateMobileMenu() {
      this.ariaExpanded = !this.ariaExpanded;
      let offscreenSidebar = document.querySelector(".offscreen-sidebar");
      let contentMask = document.querySelector(".nav-flyout-contentmask");
      let body = document.querySelector("body");

      // close menu if open
      if (offscreenSidebar.classList.contains("menu-visible")) {
        offscreenSidebar.classList.add("animating", "closing");
        offscreenSidebar.classList.remove("opening");
        setTimeout(() => {
          offscreenSidebar.classList.remove("menu-visible");
        }, 75);
        offscreenSidebar
          .querySelectorAll('[data-target="account-menu-link"]')
          .forEach((link) => {
            link.tabIndex = -1;
            link.ariaHidden = true;
          });
      } else {
        // open menu if closed, make AccountMenu links tabbable
        offscreenSidebar.classList.add("menu-visible", "animating");
        setTimeout(() => {
          offscreenSidebar.classList.add("opening");
          offscreenSidebar.classList.remove("closing");
        }, 25);
        offscreenSidebar
          .querySelectorAll('[data-target="account-menu-link"]')
          .forEach((link) => {
            link.tabIndex = 0;
            link.ariaHidden = false;
          });
      }
      offscreenSidebar.addEventListener("transitionend", () => {
        offscreenSidebar.classList.remove("animating");
      });

      if (!contentMask.style.display || contentMask.style.display === "none") {
        contentMask.style.display = "block";
      } else {
        contentMask.style.display = "none";
      }
      if (body.classList.contains("noscroll")) {
        body.classList.remove("noscroll");
      } else {
        body.classList.add("noscroll");
      }
    },
  },
};
</script>
