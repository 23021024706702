<template>
  <div
    v-click-outside="clickAway"
    class="course-announcements announcements-dropdown announcement-btn"
  >
    <button
      @click="toggleInfo"
      :aria-label="ariaCountMessage"
      class="gc-header__button gc-header__header-button--announcement"
      type="button"
    >
      <i aria-hidden="true" class="fa fa-fw fa-bell"></i>
      <div
        aria-hidden="true"
        class="notification-badge"
        v-if="unreadAnnouncementsCount"
      ></div>
    </button>
    <div v-show="active" class="dropdown-card">
      <div
        class="gc-header__announcement-list-wrapper dropdown-card__content"
        :class="{ no_announcements: !hasAnnouncements }"
      >
        <ul class="gc-header__announcement-list" v-if="hasAnnouncements">
          <li
            class="dropdown-card__list-item gc-header__announcement-list-item announcement-unread"
            v-for="announcement in announcementsList"
            :key="announcement.id"
          >
            <a
              class="announcements-dropdown__announcement-title"
              :href="getAnnouncementURL(announcement.id)"
              >{{ announcement.title }}</a
            >
            <p class="announcements-dropdown__announcement--date">
              {{ formatDate(announcement.created_at) }}
            </p>
            <div
              class="announcements-dropdown__announcement-content"
              v-html="truncatedAnnouncement(announcement.body_plain_text)"
            ></div>
          </li>
        </ul>
        <div class="gc-header__announcement-list" v-else>
          No unread announcements.
        </div>
        <div class="announcement-list__actions" v-if="hasAnnouncements">
          <a class="spire-button--primary" :href="announcementsUrl"
            >View all announcements</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { formatDatesMixin } from "@/courses/mixins/formatDates.js";
import { pluralize } from "@/courses/utilities/base.js";
import {
  courseAnnouncementListUrl,
  courseAnnouncementDetailUrl,
} from "@/courses/urls.js";
export default {
  name: "CourseAnnouncements",
  mixins: [formatDatesMixin],
  data() {
    return {
      active: false,
    };
  },
  computed: {
    ...mapState("course", [
      "courseId",
      "announcementsList",
      "unreadAnnouncementsCount",
    ]),
    announcementsUrl() {
      return courseAnnouncementListUrl.stringify({ courseId: this.courseId });
    },
    ariaCountMessage() {
      if (this.unreadAnnouncementsCount) {
        return `View ${this.unreadAnnouncementsCount} unread ${pluralize(
          "announcement",
          this.unreadAnnouncementsCount,
        )}`;
      } else {
        return "0 unread announcements";
      }
    },
    hasAnnouncements() {
      return this.announcementsList.length;
    },
  },
  methods: {
    ...mapActions("course", ["getAnnouncements"]),
    getAnnouncementURL(announcementId) {
      return courseAnnouncementDetailUrl.stringify({
        courseId: this.courseId,
        announcementId: announcementId,
      });
    },
    truncatedAnnouncement(announcement) {
      let length = 100;
      if (announcement.length > length) {
        return announcement.substr(0, length) + "…";
      } else {
        return announcement;
      }
    },
    formatDate(date) {
      return this.dateAtTime(String(date));
    },
    toggleInfo() {
      this.active = !this.active;
    },
    clickAway() {
      this.active = false;
    },
  },
};
</script>
