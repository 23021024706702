<template>
  <div v-if="header" v-click-outside="clickAway" class="course-info">
    <button
      @click="toggleInfo"
      class="gc-header__button gc-header__button--course-info"
      type="button"
    >
      <i class="fa fa-w fa-circle-info" />
      Course Info
    </button>
    <div v-if="active" class="dropdown-card">
      <ul class="dropdown-card__content">
        <strong>Course Info</strong>
        <ul>
          <li
            v-if="headerData.location"
            class="dropdown-card__list-item course-info__list-item"
          >
            {{ headerData.location }}
          </li>
          <li
            v-if="hasTimeOrLocation"
            class="dropdown-card__list-item course-info__list-item"
          >
            {{ headerData.meeting_times }}
          </li>
          <li class="dropdown-card__list-item course-info__list-item">
            <a :href="syllabusUrl" class="dropdown-card__link">
              <i class="fa fa-fw fa-file-text"></i>
              Course Syllabus
            </a>
          </li>
        </ul>
        <template v-if="hasOfficeHourLocation">
          <strong>Office Location & Hours</strong>
          <ul>
            <li
              v-if="headerData.office"
              class="dropdown-card__list-item course-info__list-item"
            >
              {{ headerData.office }}
            </li>
            <li
              v-if="headerData.office_hours"
              class="dropdown-card__list-item course-info__list-item"
            >
              Office Hours: {{ headerData.office_hours }}
            </li>
          </ul>
        </template>
        <template v-if="hasInstructors">
          <strong>{{ instructorCount }}</strong>
          <ul>
            <li class="dropdown-card__list-item course-info__list-item">
              {{ instructorString }}
            </li>
          </ul>
        </template>
        <template v-if="isStudentHasTeam">
          <strong>{{ termForTeam }}</strong>
          <ul>
            <li
              v-if="isStudentHasTeam"
              class="dropdown-card__list-item course-info__list-item"
            >
              <i class="fa fa-fw fa-trophy"></i>
              {{ headerData.team_term }} {{ headerData.team_info.name }}
            </li>
            <li
              v-for="(team_leader, index) in teamGSIs"
              :key="index"
              class="dropdown-card__list-item course-info__list-item"
            >
              <i class="fa fa-fw fa-flag"></i>
              {{ headerData.team_leader_term }} {{ team_leader }}
            </li>
          </ul>
        </template>
        <template v-if="hasContact">
          <strong>Contact</strong>
          <ul>
            <li
              v-if="headerData.class_email"
              class="dropdown-card__list-item course-info__list-item"
            >
              <a :href="classEmail">
                <i class="fa fa-fw fa-envelope"></i>
                {{ headerData.class_email }}
              </a>
            </li>
            <li
              v-if="headerData.twitter_handle"
              class="dropdown-card__list-item course-info__list-item"
            >
              <a :href="classTwitter">
                <i class="fa fa-fw fa-twitter"></i>
                {{ headerData.twitter_handle }}
              </a>
            </li>
            <li
              v-if="headerData.twitter_hashtag"
              class="dropdown-card__list-item course-info__list-item"
            >
              <a :href="classTwitterHashtag">
                <i class="fa fa-fw fa-slack"></i>
                {{ headerData.twitter_hashtag }}
              </a>
            </li>
            <li
              v-if="headerData.phone"
              class="dropdown-card__list-item course-info__list-item"
            >
              <i class="fa fa-fw fa-phone"></i> {{ headerData.phone }}
            </li>
          </ul>
        </template>
      </ul>
    </div>
  </div>
  <!-- Use in sidenav -->
  <div v-else class="mb-1">
    <accordion-component
      accordion-trigger-button-class="course-info-btn-mobile accordion-trigger__button--after w-100"
      accordion-content-wrapper-class="accordion-content-wrapper--course-info"
      accordion-content-class="p-0"
    >
      <template #heading>
        <span>
          <i class="fa fa-fw fa-bank mr-1"></i>
          Course Info
        </span>
      </template>
      <template #content v-if="active">
        <ul :class="{ open: active }" class="course-info">
          <li
            v-if="hasInstructors"
            class="dropdown-card__list-item course-info__list-item"
          >
            <i class="fa fa-fw fa-flash"></i>
            {{ instructorCount }}: {{ instructorString }}
          </li>
          <li class="not-a-link" v-if="headerData.location">
            <i class="course-info__icon fa fa-fw fa-calendar"></i>
            <span class="course-info__content">
              <template v-if="headerData.meeting_times">
                {{ headerData.meeting_times }}
              </template>
            </span>
          </li>
          <li class="not-a-link" v-if="headerData.location">
            <i class="course-info__icon fa fa-fw fa-bank mr-1"></i>
            <span class="course-info__content">
              <template v-if="headerData.meeting_times">
                {{ headerData.location }}
              </template>
            </span>
          </li>
          <li class="not-a-link" v-if="headerData.office">
            <i class="course-info__icon fa fa-hourglass-end"></i>
            <span class="course-info__content">
              Office Hours: {{ headerData.office }}
              <template v-if="headerData.office_hours">
                {{ headerData.office_hours }}
              </template>
            </span>
          </li>
          <li class="dropdown-card__list-item course-info__list-item">
            <a
              :href="syllabusUrl"
              class="dropdown-list-item course-info__list-item"
            >
              <i class="fa fa-fw fa-file-text"></i>
              Course Syllabus
            </a>
          </li>
          <template v-if="isStudentHasTeam">
            <li
              class="not-a-link dropdown-card__list-item course-info__list-item"
            >
              <i class="fa fa-fw fa-trophy"></i>
              {{ headerData.team_term }} {{ headerData.team_info.name }}
            </li>
            <li v-for="(team_leader, index) in teamGSIs" :key="index">
              <i class="fa fa-fw fa-flag"></i>
              {{ headerData.team_leader_term }} {{ team_leader }}
            </li>
          </template>
        </ul>
      </template>
    </accordion-component>
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters, mapState } from "vuex";
import AccordionComponent from "@/courses/components/structure/AccordionComponent.vue";
import { pluralize } from "@/courses/utilities/base.js";
import { courseSyllabusUrl } from "@/courses/urls.js";

export default {
  name: "CourseInfo",
  props: {
    header: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  components: {
    AccordionComponent,
  },
  computed: {
    ...mapGetters("course", ["termForTeam"]),
    ...mapState("course", ["headerData"]),
    ...mapState("navigation", ["courseId"]),
    classEmail() {
      return `mailto:${this.headerData.class_email}`;
    },
    classTwitter() {
      return `http://www.twitter.com/${this.headerData.twitter_handle}`;
    },
    classTwitterHashtag() {
      return `http://www.twitter.com/hashtag/${this.headerData.twitter_hashtag}`;
    },
    hasTimeOrLocation() {
      return this.headerData.meeting_times || this.headerData.location;
    },
    hasOfficeHourLocation() {
      return this.headerData.office || this.headerData.office_hours;
    },
    isStudentHasTeam() {
      return this.headerData.team_info.name;
    },
    teamGSIs() {
      return this.headerData.team_info.team_leaders;
    },
    hasInstructors() {
      return this.headerData?.instructors_of_record?.length !== 0 ?? false;
    },
    instructorCount() {
      let count = this.headerData.instructors_of_record.length;
      return `${pluralize("Instructor", count)}`;
    },
    instructorString() {
      return this.headerData.instructors_of_record.join(", ");
    },
    hasContact() {
      let data = this.headerData;
      return (
        data.classEmail ||
        data.twitter_handle ||
        data.twitter_hashtag ||
        data.phone
      );
    },
    syllabusUrl() {
      return courseSyllabusUrl.stringify({ courseId: this.courseId });
    },
  },
  methods: {
    toggleInfo() {
      this.active = !this.active;
    },
    clickAway() {
      this.active = false;
    },
    setActive(windowWidth) {
      let mobileSidebarMax = 1024;
      this.active = windowWidth <= mobileSidebarMax;
    },
  },
  created() {
    let windowWidth = window.innerWidth;
    this.setActive(windowWidth);

    window.addEventListener("resize", (e) => {
      this.setActive(e.target.innerWidth);
    });
  },
};
</script>
