<template>
  <div class="search">
    <div class="has-form" role="search" v-click-outside="handleStudentEscape">
      <input
        id="studentSearch"
        :placeholder="placeholderText"
        class="students-search-query search-input"
        :aria-label="placeholderText"
        type="search"
        v-model="studentSearchText"
      />
      <focus-trap v-if="studentSearchText.length && hasStudentResults">
        <ul class="dropdown-menu d-block" tabindex="-1">
          <li v-for="user in filteredStudentResults" :key="user.id">
            <a :href="user.url">{{ user.name }}</a>
          </li>
        </ul>
      </focus-trap>
    </div>
    <div class="has-form" role="search" v-click-outside="handleCourseEscape">
      <input
        id="courseSearch"
        placeholder="Switch Course"
        class="courses-search-query search-input"
        aria-label="Search your courses"
        type="search"
        v-model="courseSearchText"
      />
      <focus-trap v-if="courseSearchText.length && hasCourseResults">
        <ul class="dropdown-menu d-block" tabindex="-1">
          <li>
            <h3 class="h6 course-search__header pl-1">Matching Courses</h3>
          </li>
          <li
            v-for="course in filteredCourseResults"
            :key="course.id"
            class="course-search__list-item"
          >
            <a :href="course.url" class="course-search__list-link">{{
              course.name
            }}</a>
          </li>
        </ul>
      </focus-trap>
    </div>
  </div>
</template>
<script>
import { debounce } from "lodash";
import { mapGetters } from "vuex";
import { apiClient } from "@/courses/services/GenericService.js";
import { FocusTrap } from "focus-trap-vue";
import { pluralize } from "@/courses/utilities/base.js";
import { isEmpty } from "lodash";
import { courseSearchAPIUrl, studentSearchAPIUrl } from "@/courses/urls.js";
export default {
  name: "SearchComponent",
  components: {
    FocusTrap,
  },
  data() {
    return {
      courseSearchText: "",
      studentSearchText: "",
      studentSearchResults: [],
      courseSearchResults: [],
    };
  },
  computed: {
    ...mapGetters("course", ["termForStudent"]),
    placeholderText() {
      return `Search ${pluralize(this.termForStudent)}`;
    },
    filteredCourseResults() {
      return this.courseSearchResults.filter((result) => {
        return result.search_string
          .toLowerCase()
          .includes(this.courseSearchText.toLowerCase());
      });
    },
    filteredStudentResults() {
      return this.studentSearchResults.filter((result) => {
        return result.search_string
          .toLowerCase()
          .includes(this.studentSearchText.toLowerCase());
      });
    },
    hasStudentResults() {
      return !isEmpty(this.filteredStudentResults);
    },
    hasCourseResults() {
      return !isEmpty(this.filteredCourseResults);
    },
  },
  methods: {
    handleCourseEscape() {
      this.courseSearchText = "";
      this.courseSearchResults = [];
    },
    handleStudentEscape() {
      this.studentSearchText = "";
      this.studentSearchResults = [];
    },
    searchCourses: debounce(function (text) {
      if (text.length > 0 && !this.hasCourseResults) {
        apiClient
          .get(courseSearchAPIUrl.stringify())
          .then((response) => {
            this.courseSearchResults = response.data;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }, 500),
    searchStudents: debounce(function (text) {
      if (text.length > 0 && !this.hasStudentResults) {
        apiClient
          .get(studentSearchAPIUrl.stringify())
          .then((response) => {
            this.studentSearchResults = response.data;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }, 500),
  },
  watch: {
    courseSearchText(val) {
      this.searchCourses(val);
    },
    studentSearchText(val) {
      this.searchStudents(val);
    },
  },
};
</script>
